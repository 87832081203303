<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Dados do atendimento</h2>
        <hr />
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="paciente"
                label="Paciente"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="prontuario"
                label="Prontuário"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="cpf"
                label="CPF"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="especialidade"
                label="Especialidade"
                required
              ></v-text-field>
            </div>
          </div>

          <router-link
            :to="
              this.$route.params.setor == 2
                ? '/FilaDeAtendimento'
                : '/FilaAtendimentoConsulta'
            "
            v-slot="{ href, navigate }"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "visualizarUsuario",
  async created() {
    try {
      const { data } = await ambulatorio.indexEncaminhamento(
        this.$route.params.id
      );

      this.paciente = data.dados_paciente.pessoa.nome;
      this.prontuario = data.dados_prontuario.numero;
      this.cpf = data.dados_paciente.pessoa.cpf;
      this.especialidade = data.especialidade.descricao;
      this.prioridade = data.paciente.prioridade;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      paciente: "",
      prontuario: "",
      cpf: "",
      especialidade: "",
      prioridade: "false"
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visualizar dados do atendimento" }
    ]);
  }
};
</script>
